<template>
    <div class="userinfo">
        <div class="index_head">
            <div class="index_top">
                <div class="left" @click="redirectIndex">
                    <img src="../../../public/logo.png" alt=""/>
                    <p>隆昂烟草图册查询</p>
                </div>
                <div class="right">
                    <div class="user_info">
                        <div class="user_head">
                            <img :src="userInfo.avatar" alt=""/>
                        </div>
                        <div class="user_mes">
                            <h3>{{ userInfo.realname }}</h3>
                            <p>部门：<span>{{ userInfo.department }}</span></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="user_info_main">
            <div class="left">
                <div class="box">
                    <div class="item active">
                        账号信息
                    </div>
                    <div class="item" @click="usersc">
                        我的收藏
                    </div>
                    <div class="item" @click="userpassword">
                        修改密码
                    </div>
                    <div class="item" @click="logout">
                        退出登录
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="info_top">
                    <div class="info_top_word">
                        账号信息
                    </div>
                    <div class="info_top_head">
                        <img :src="userInfo.avatar" alt=""/>

                        <el-upload
                            class="upload-demo"
                            :action="baseURL + '/common/upload/uploadImage'"
                            :headers="{ Authorization: 'Bearer ' + token }"
                            accept="image/*"
                            :on-remove="handleRemove"
                            multiple
                            :limit="1"
                            :show-file-list="false"
                            :on-exceed="handleExceed"
                            :on-success="handleSuccess"
                        ><p>上传头像</p></el-upload>
                    </div>
                </div>
                <div class="info_middle">
                    <div class="item">
                        <div class="info_top_word">
                            姓名
                        </div>
                        <div class="info_top_input">
                            <input type="text" name="realname" v-model="userInfo.realname" placeholder="姓名"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info_top_word">
                            手机号
                        </div>
                        <div class="info_top_input">
                            <input type="text" disabled="true" :value="userInfo.username" placeholder="手机号"/>
                        </div>
                    </div>
                </div>
                <div class="info_bottom">
                    <div class="item">
                        <div class="info_top_word">
                            部门
                        </div>
                        <div class="info_top_input">
                            <input type="text" disabled="true" :value="userInfo.department" placeholder="部门"/>
                        </div>
                    </div>
                </div>
                <div class="contr" style="margin-top: 20px;">
                    <el-row><el-button @click="saveAvatar" type="warning" style="width: 200px">保存</el-button></el-row>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    data() {
        return {
            baseURL: '',
            fileList: [],
            token: '',
            Authorization: '',

            userInfo: {},
        }
    },

    mounted() {
        this.baseURL = process.env.VUE_APP_BASE_URL;
        this.Authorization = 'Authorization';
        this.token = localStorage.getItem('access_token');
        this.getUserInfo();
    },

    methods: {
        /**
         * 个人信息
         */
        getUserInfo() {
            this.$http.get('/user/getUserInfo').then(res => {
                this.userInfo = res.data.data
            });
        },

        handleExceed() {
            this.$message.warning(`当前限制选择 1 个文件`);
        },
        handleSuccess(response, file, fileList) {
            console.log(111111);
            console.log(response);
            if(response.code == 0){
                this.userInfo.avatar = response.data;
                this.saveAvatar();
            }else{
                this.$message.error('上传失败');
            }

            console.log(file);
            console.log(fileList);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },

        saveAvatar(){
            var that = this;
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post('/user/edit', {
                id: that.userInfo.id,
                username: that.userInfo.username,
                avatar: that.userInfo.avatar,
                realname: that.userInfo.realname,
            }).then(res => {
                loading.close();
                if(res.data.code == 0){
                    this.$message.success(res.data.msg);
                    setTimeout(function(){
                        that.getUserInfo();
                    }, 1500);
                }else{
                    this.$message({type: 'error', message: res.data.msg})
                }

            });
        },

        redirectIndex(){
            this.$router.push('/')
        },
        usersc() {
            this.$router.push('/usersc')
        },
        userpassword() {
            this.$router.push('/userpassword')
        },
        logout(){
            this.$messageBox.confirm('确定要退出吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.removeItem('access_token')
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
                this.$router.push('/login')
            });
        },
    }
};
</script>

<style lang="less" scoped>
.index_head {
    width: 100%;
    background: #fff;

    .index_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        .left {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 81px;
                margin-right: 24px;
            }

            p {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 600;
                font-size: 32px;
                color: #231815;
                line-height: 48px;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .user_info {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;

                .user_head {
                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                }

                .user_mes {
                    h3 {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 600;
                        font-size: 14px;
                        color: #231815;
                        line-height: 22px;
                    }

                    p {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 12px;
                        color: #8D8D8D;
                        line-height: 18px;
                        display: flex;
                        align-items: center;

                        span {
                            color: #443B39;
                        }
                    }
                }

            }
        }
    }


}

.user_info_main {
    width: 60%;
    margin: 0 auto;
    margin-top: 26px;
    display: flex;
    justify-content: space-between;

    .left {
        width: 200px;
        margin-right: 30px;

        .box {
            background: #FFFFFF;
            border-radius: 16px 16px 16px 16px;
            padding: 18px 0;

            .item {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #8D8D8D;
                line-height: 32px;
                margin-bottom: 8px;
                padding-left: 24px;
                cursor: pointer;
            }

            .item.active {
                border-left: 5px solid #EB9114;
            }

            .item:last-child {
                margin-bottom: 0;
            }
        }

    }

    .right {
        flex: 1;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 30px;
        box-sizing: border-box;

        .info_top_word {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #231815;
            line-height: 24px;
            margin-bottom: 12px;
        }

        .info_top_head {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin-right: 10px;
            }

            p {
                width: 88px;
                height: 38px;
                background: #F4EDE8;
                border-radius: 8px 8px 8px 8px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #231815;
                line-height: 38px;
                text-align: center;
            }
        }

        .info_middle {
            display: flex;
            align-items: center;
            margin: 25px 0;

            .item {
                flex: 0 0 40%;
                margin-right: 20px;

                .info_top_word {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #231815;
                    line-height: 24px;
                    margin-bottom: 12px;
                }

                .info_top_input {

                    input {
                        display: block;
                        width: 100%;
                        padding: 12px;
                        background: #F6F6F6;
                        border-radius: 8px 8px 8px 8px;
                        border: 1px solid #DCDCDC;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #443B39;
                        line-height: 22px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .info_bottom {
            display: flex;
            align-items: center;

            .item {
                flex: 0 0 40%;
                margin-right: 20px;

                .info_top_word {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #231815;
                    line-height: 24px;
                    margin-bottom: 12px;
                }

                .info_top_input {

                    input {
                        display: block;
                        width: 100%;
                        padding: 12px;
                        background: #F6F6F6;
                        border-radius: 8px 8px 8px 8px;
                        border: 1px solid #DCDCDC;
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #443B39;
                        line-height: 22px;
                        box-sizing: border-box;
                    }
                }
            }

        }
        .contr{
            display: flex;
            align-items: center;
            //justify-content: center;
            p{
                width: 60px;
                height: 32px;
                border-radius: 3px 3px 3px 3px;
                border: 1px solid #C7C7C7;
                text-align: center;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #443B39;
                line-height: 32px;
                margin:0 10px;
                cursor: pointer;
            }
            p:last-child{
                background: #EB9114;
                border: 1px solid #EB9114;
                color: #fff;
            }
        }
    }
}
</style>
